<template>
  <list-crud
    :loading="loading"
    :per-page.sync="perPage"
    :per-page-options="perPageOptions"
    :search-query.sync="searchQuery"
    :items="items"
    :table-columns="tableColumns"
    :sort-by.sync="sortBy"
    :is-sort-dir-desc.sync="isSortDirDesc"
    :current-page.sync="currentPage"
    :from="from"
    :to="to"
    :total-items="totalItems"
    btn-create-text="Пригласить"
    @click-btn-create="$router.push({ name: 'invites.create' })"
  >
    <template #cell(role_id)="data">
      {{ get(roles.find(i => i.id === data.item.role_id), 'name', '') }}
      <div class="small text-secondary">
        {{ get(roles.find(i => i.id === data.item.role_id), 'desc', '') }}
      </div>
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item @click="deleteItem(data.item)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Удалить</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </list-crud>
</template>

<script>
import {
  getCurrentInstance, onMounted, ref,
} from 'vue'
import get from 'lodash/get'
import {
  BCard, BCardBody, BRow, BCol, BLink, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCrudList from '@/composables/useCrudList'
import { getRoles as getRolesApi } from '@/services/main-api/business/roles'
import { getInvites, deleteInvite } from '@/services/main-api/business/invites'
import ListCrud from '@/components/crud/ListCrud.vue'

export default {
  name: 'ListView',
  components: {
    ListCrud,
    BCard,
    BCardBody,
    BRow,
    BCol,
    vSelect,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const instance = getCurrentInstance().proxy
    const roles = ref([])

    const getItems = async () => {
      let search = null
      if (instance.searchQuery) {
        search = `${instance.searchQuery}`
      }

      try {
        const res = await getInvites({
          company: instance.$store.getters['workingMode/company_id'],
          perPage: instance.perPage,
          sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
          orderBy: instance.sortBy,
          page: instance.currentPage,
          search,
        })

        instance.items = res.data.data
        instance.currentPage = res.data.meta.current_page
        instance.from = res.data.meta.from
        instance.to = res.data.meta.to
        instance.totalItems = res.data.meta.total
      } catch (e) {
        instance.items = []
      }
    }

    const getRoles = async () => {
      try {
        const res = await getRolesApi({
          company: instance.$store.getters['workingMode/company_id'],
          perPage: 1000,
          page: 1,
        })
        roles.value = res.data.data
      } catch (e) {
        roles.value = []
      }
    }

    const {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,
    } = useCrudList({
      sortBy: 'created_at',
      isSortDirDesc: false,
      tableColumns: [
        { key: 'phone', label: 'Телефон', sortable: true },
        { key: 'role_id', label: 'Роль', sortable: false },
        { key: 'created_at', label: 'Создано', sortable: true },
        { key: 'actions', label: 'Действия' },
      ],
      getItems,
      getMoreData: async () => {
        await getRoles()
      },
      deleteEntity: item => deleteInvite(item.id),
      getQuestionTextBeforeDeletion: item => `Приглашение для ${item.phone} будет удалено`,
      getTextAfterDeletion: () => 'Приглашение было удалено.',
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,

      roles,

      get,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
